.footer-contianer{
    text-align: center;
    color: #605d5d;
    margin-bottom: 30px;
}

.footer-contianer a{
    text-decoration: none;
    font-weight: bold;
    color: #605d5d;
}