.App {
  text-align: left;
  flex-grow: 1;
}

.App-logo {
  width: 240px;
  pointer-events: none;
}

.App-header {
  background-color:  #FFF; /*#282c34; */
  min-height: 60px;
  max-height: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.separator{
  margin-top: 60px !important;
}

h1{
  color: #5b5b5b;
}

.App-link {
  color: #61dafb;
}

Paper {
  margin-bottom: 50px;
}

.UploadContainer{
  width: 100%;
  height:250px;
  background: #e8e8e8  !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-bottom: 1.2px solid black;
  margin-bottom: 5px;
}
.UploadContainerError{
  border-bottom: 1.2px solid red !important;
}

.UploadContainer img{
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}


.UploadInfoContainer{
  background: aliceblue;
  padding: 20px;
  border-radius: 5px;
  text-align: left;
  border-color: #0081f2;
  border: 1px solid #bbdbf7;
  margin-top: 20px;
}

.UploadArtworkContainer{
  width: 100%;
  min-height:656px;
  height: 656px;
  max-height: 656px;
  background: #e8e8e8  !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-bottom: 1.2px solid black;
  /* margin-bottom: 20px; */
  overflow: clip;
}

.UploadArtworkContainer img{
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.UploadImageButton{
  right: 0px;
  place-self: end right;
  text-align: right;
  width: 100%;
  margin-top: 10px;
  height: 39.5px;
  background: #515151 !important;
  color: white !important;
}

.canvas_input_icon{
  width: 22px !important;
  color: lightslategrey;
}

.canvas_input_icon_90{
  transform: rotateZ(90deg);
}

.canvas_input_icon_45{
  transform: rotateZ(45deg);
}

.MuiFormLabel-asterisk {
  color: red !important;
}
.MuiInputLabel-asterisk {
  color: red !important;
}